import * as e from './enum';
import { deCryptUploadTime } from '../crypto/Crypto';
import { LOGOUT } from '../common/route';
import { isCheckPlatform } from './config';
import { confirmDialog } from 'primereact/confirmdialog';
import axios from 'axios';


export function degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
}

/**
 * Check app version
 */


export const checkAppVersion = async () => {
    const app_version = localStorage.getItem('APP_VERSION');
    const app_update_status = localStorage.getItem('APP_UPDATE_STATUS')
    if (app_version === undefined || app_version === null) {
        localStorage.clear();
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });
        }

        localStorage.setItem('APP_VERSION', e.version);
    }

    axios.get(`https://api.windowaudit.com.vn/check-version?id=${e.version}`)
        .then(res => {
            if (res.status === e.STATUS_RESPONSE_OK) {
                const {
                    data
                } = res;
                if (data.status !== e.STATUS_RESPONSE_OK) {
                    // if (parseInt(localStorage.getItem('APP_VERSION')) !== e.version) {
                    confirmDialog({
                        message: `Vui lòng cập nhật phiên bản mới để sử dụng dịch vụ !`,
                        header: 'Cập nhật',
                        icon: 'pi pi-exclamation-triangle',
                        rejectClassName: "d-none",
                        acceptLabel: "ĐỒNG Ý",
                        accept: () => {
                            localStorage.clear();
                            if ('caches' in window) {
                                caches.keys().then((names) => {
                                    // Delete all the cache files
                                    names.forEach(name => {
                                        caches.delete(name);
                                    })
                                });
                            }
                            // Makes sure the page reloads. Changes are only visible after you refresh.
                            window.location.reload();
                            //   localStorage.setItem('APP_VERSION', e.version);
                            localStorage.setItem('APP_UPDATE_STATUS', true);
                        },
                    });
                } else {
                    if (app_update_status && app_update_status === "true") {
                        confirmDialog({
                            message: `Cập nhật thành công !`,
                            header: 'Cập nhật',
                            icon: 'pi pi-check',
                            rejectClassName: "d-none",
                            acceptLabel: "ĐỒNG Ý",
                            accept: () => {
                                localStorage.removeItem('APP_UPDATE_STATUS');
                            },
                        });
                    }
                }

            }
        })
        .catch(err => {
            console.log(err);
        })

}

/**
 * Kiểm tra khoảng cách cho phép cập nhật Survey
 * @param {number} firstLat 
 * @param {number} firstLong 
 * @param {number} secondLat 
 * @param {number} secondLong 
 */
export function checkAllowedDistance(firstLat, firstLong, secondLat, secondLong) {
    let earthRadiusKm = 6371;
    let dLat = degreesToRadians(secondLat - firstLat);
    let dLon = degreesToRadians(secondLong - firstLong);
    firstLat = degreesToRadians(firstLat);
    secondLat = degreesToRadians(secondLat);

    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(firstLat) * Math.cos(secondLat);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
}

/**
 * Kiểm tra platform cho phép upload hình ảnh
 * @param {string} platform 
 * @returns true: Disable, false: Allow
 */
export function checkPlatform(platform) {
    if (isCheckPlatform) {
        switch (platform) {
            case "Win16":
            case "Win32":
            case "WinCE":
            case "Mac68K":
            case "MacPPC":
            case "MacIntel":
            case "ARM":
                return true;
            default:
                return false;
        }
    } else {
        return false;
    }
}

/**
 * Kiểm tra có phải user đang sử dụng trình duyệt Puffin không ?
 * vì Puffin cho phép upload hình ảnh, không cần phải sử dụng camera
 * @param {*} userAgent 
 */
export function checkPuffinBrowser(userAgent) {
    if (userAgent.includes("Puffin")) {
        return true
    } else {
        return false
    }
}

/**
 * Kiểm tra có phải user đang sử dụng trình duyệt Firefox không ?
 * vì Firefox cho phép upload hình ảnh, không cần phải sử dụng camera
 * @param {*} userAgent 
 */
export function checkFirefoxBrowser(userAgent) {
    if (userAgent.includes("Firefox")) {
        return true
    } else {
        return false
    }
}

/**
 * Kiểm tra có phải user đang sử dụng trình duyệt UCBrowser hoặc UCTurbo không ?
 * vì UCBrowser hoặc UCTurbo cho phép upload hình ảnh, không cần phải sử dụng camera
 * @param {*} userAgent 
 */
export function checkUCBrowser(userAgent) {
    if (userAgent.includes("UCBrowser") || userAgent.includes("UCTurbo")) {
        return true
    } else {
        return false
    }
}

/**
 * Kiểm tra có phải user đang sử dụng Webview không ?
 * vì Webview cho phép upload hình ảnh, không cần phải sử dụng camera
 * @param {*} userAgent 
 */
export function checkWebviewBrowser(userAgent) {
    if (userAgent.includes("wv")) {
        return true
    } else {
        return false
    }
}

/**
 * Kiểm tra có phải user đang sử dụng Zalo không ?
 * vì Zalo cho phép upload hình ảnh, không cần phải sử dụng camera
 * @param {*} userAgent 
 */
export function checkZalo(userAgent) {
    if (userAgent.includes("Zalo")) {
        return true
    } else {
        return false
    }
}

/**
 * Kiểm tra có phải user đang sử dụng hdh Linux không ?
 * vì Linux cho phép upload hình ảnh, không cần phải sử dụng camera
 * @param {*} platform 
 */
export function checkLinuxOs(platform) {
    if (platform === "Linux x86_64") {
        return true
    } else {
        return false
    }
}

export function processResponse(data) {
    if (data) {
        switch (data.status) {
            case e.STATUS_RESPONSE_TOKEN_INVALID:
                document.location.href = LOGOUT;
                break;
            default:
                alert(data.message);
                break;
        }
    }
}

/**
 * uploadTime: 06:30-20:00
 */
export function checkTimeUpload() {
    // return '';
    try {
        let timeLimit = deCryptUploadTime();
        let timeLimitArr = timeLimit.split('-');
        let minTimeArr = timeLimitArr[0].split(':');
        let maxTimeArr = timeLimitArr[1].split(':');
        let minTime = parseFloat(minTimeArr[0]) + parseFloat(minTimeArr[1]) / 60;
        let maxTime = parseFloat(maxTimeArr[0]) + parseFloat(maxTimeArr[1]) / 60;
        let objDate = new Date();
        let timer = objDate.getHours() + objDate.getMinutes() / 60;
        if (timer >= maxTime || timer <= minTime) {
            // return '';
            return `Chức năng tạo survey đã bị khóa trong khoảng thời gian từ ${timeLimitArr[1]} tới ${timeLimitArr[0]}`;
        }
        else {
            return '';
        }
    } catch (error) {
        return '';
    }
}

/**
 * 
 * @param {Date} endDate (2020-10-29T14:17:45)
 */
export function countExpiredEvent(endDate) {
    try {
        var eventDate = new Date(endDate);
        var nowDate = new Date();
        var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        var days = Math.round(Math.abs((eventDate - nowDate) / oneDay));
        if (days < 0)
            return 0;
        return days;
    }
    catch (error) {
        return 0;
    }
}

/**
 * 
 * @param {evt} element
 */

export function setInputFilter(evt) {
    var theEvent = evt || window.event;
    // var key;
    var key = theEvent.keyCode || theEvent.which;
    // Handle paste
    if (theEvent.type === 'paste') {
        key = theEvent.clipboardData.getData('text/plain');
    } else {
        // Handle key press
        // var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
    }
}

/**
 * 
 * @param {phone} element
 */

export function checkPhone(phone) {
    return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(phone);
}

/**
 * 
 * @param {string} str 
 * @param {string} find 
 * @param {string} replace 
 */

export function replaceAll(str, replace) {
    // return str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, replace);
    return str.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, replace);
}

export function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d * 1000;
    //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    //:::                                                                         :::
    //:::  This routine calculates the distance between two points (given the     :::
    //:::  latitude/longitude of those points). It is being used to calculate     :::
    //:::  the distance between two locations using GeoDataSource (TM) prodducts  :::
    //:::                                                                         :::
    //:::  Definitions:                                                           :::
    //:::    South latitudes are negative, east longitudes are positive           :::
    //:::                                                                         :::
    //:::  Passed to function:                                                    :::
    //:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
    //:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
    //:::    unit = the unit you desire for results                               :::
    //:::           where: 'M' is statute miles (default)                         :::
    //:::                  'K' is kilometers                                      :::
    //:::                  'N' is nautical miles                                  :::
    //:::                                                                         :::
    //:::  Worldwide cities and other features databases with latitude longitude  :::
    //:::  are available at https://www.geodatasource.com                         :::
    //:::                                                                         :::
    //:::  For enquiries, please contact sales@geodatasource.com                  :::
    //:::                                                                         :::
    //:::  Official Web site: https://www.geodatasource.com                       :::
    //:::                                                                         :::
    //:::               GeoDataSource.com (C) All Rights Reserved 2018            :::
    //:::                                                                         :::
    //:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    // let unit ="K"
    // if ((lat1 == lat2) && (lon1 == lon2)) {
    //     return 0;
    // }
    // else {
    //     var radlat1 = Math.PI * lat1/180;
    //     var radlat2 = Math.PI * lat2/180;
    //     var theta = lon1-lon2;
    //     var radtheta = Math.PI * theta/180;
    //     var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    //     if (dist > 1) {
    //         dist = 1;
    //     }
    //     dist = Math.acos(dist);
    //     dist = dist * 180/Math.PI;
    //     dist = dist * 60 * 1.1515;
    //     if (unit=="K") { dist = dist * 1.609344 }
    //     if (unit=="N") { dist = dist * 0.8684 }
    //     return dist;
    // }
}

function deg2rad(deg) {
    return deg * (Math.PI / 180)
}