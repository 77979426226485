import { combineReducers } from 'redux';
import common from './common';
import commonService from './commonService';
import auth from './auth';
import account from './account';
import storeList from './storeList';
import storeDetail from './storeDetail';
import survey from './survey';
import location from './location';
import lastLocation from './lastLocation';
import notification from './notification';
const reducers = combineReducers({
    auth,
    account,
    common,
    commonService,
    storeList,
    storeDetail,
    survey,
    location,
    lastLocation,
    notification
});

export default reducers;