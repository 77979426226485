import * as actionTypes from '../constants/actionTypes';

const initialState = {
    surveys: null,
    surveyImages: null,
    status: 0,
    isLoading: false,
    //Thêm cho surveyImage
    isLoadingSurveysImage: false,
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SURVEY_INI:
            return state;
        case actionTypes.SURVEY_SET:
            return {
                ...state,
                surveys: action.data,
                isLoading: action.loading,
                message: null
            }
        case actionTypes.SURVEY_SET_IMAGES:
            return {
                ...state,
                surveyImages: action.data,
                isLoadingSurveysImage: action.loading,
                message: null
            }
        case actionTypes.SURVEY_SET_STATUS:
            return {
                ...state,
                status: action.data,
                isLoading: action.loading,
                message: null
            }
        case actionTypes.LOADING:
            // console.log('1', action)
            return {
                ...state,
                isLoading: action.loading
            }
        case actionTypes.SURVEY_ERROR:
            return {
                ...state,
                isLoading: false,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;