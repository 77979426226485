import React from 'react';
const TemplateLogin = (props) => {
    const {
        windowHeight,
        children
    } = props;

    return (
        <div style={{width: "100%", height: windowHeight}}>
            {children}
        </div>
    )
}
export default TemplateLogin;