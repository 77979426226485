//AUTH
export const AUTH_INI = 'AUTH_INI';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_FAIL = 'AUTH_FAIL';
// ACCOUNT
export const ACCOUNT_INI = 'ACCOUNT_INI';
export const ACCOUNT_SET = 'ACCOUNT_SET';
export const ACCOUNT_SET_CHANGE_PASSWORD = 'ACCOUNT_SET_CHANGE_PASSWORD';
export const ACCOUNT_MESSAGE = 'ACCOUNT_MESSAGE';
// COMMON
export const COMMON_INI = 'COMMON_INI';
export const COMMON_SET_TITLE = 'COMMON_SET_TITLE';
export const COMMON_SET_ROUTER = 'COMMON_SET_ROUTER';
export const COMMON_SET_EVENT = 'COMMON_SET_EVENT';
export const COMMON_SET_STORETYPE = 'COMMON_SET_STORETYPE';
export const COMMON_SET_EVENT_STORETYPE = 'COMMON_SET_EVENT_STORETYPE';
// GLOBAL SERVICE
export const SERVICE_INI = 'SERVICE_INT';
export const SERVICE_SET_STORE_TYPE = 'SERVICE_SET_STORE_TYPE';
export const SERVICE_SET_PROVINCE = 'SERVICE_SET_PROVINCE';
export const SERVICE_SET_EVENT = 'SERVICE_SET_EVENT';
export const SERVICE_SET_EVENT_IMAGES = 'SERVICE_SET_EVENT_IMAGES';
export const SERVICE_SET_CHANNEL = 'SERVICE_SET_CHANNEL';
export const SERVICE_SET_CHANNEL_PACKAGE = 'SERVICE_SET_CHANNEL_PACKAGE';
export const SERVICE_SET_MESSAGE = 'SERVICE_SET_MESSAGE';
// STORE LIST
export const STORE_LIST_INI = 'STORE_LIST_INI';
export const STORE_LIST_SET = 'STORE_LIST_SET';
export const STORE_LIST_ERROR = 'STORE_LIST_ERROR';

// NOTIFICATION LIST
export const NOTIFICATION_LIST_INI = 'NOTIFICATION_LIST_INI';
export const NOTIFICATION_LIST_SET = 'NOTIFICATION_LIST_SET';
export const NOTIFICATION_LIST_ERROR = 'NOTIFICATION_LIST_ERROR';

// STORE DETAIL
export const STORE_DETAIL_INI = 'STORE_DETAIL_INI';
export const STORE_DETAIL_SET = 'STORE_DETAIL_SET';
export const STORE_DETAIL_CREATE = 'STORE_DETAIL_CREATE';
export const STORE_DETAIL_ERROR = 'STORE_DETAIL_ERROR';
// SURVEY
export const SURVEY_INI = 'SURVEY_INI';
export const SURVEY_SET = 'SURVEY_SET';
export const SURVEY_SET_IMAGES = 'SURVEY_SET_IMAGES';
export const SURVEY_SET_STATUS = 'SURVEY_SET_STATUS';
export const SURVEY_ERROR = 'SURVEY_ERROR';
// LOCATION
export const LOCATION_INI = 'LOCATION_INI';
export const LAT_LOCATION_INI = 'LAT_LOCATION_INI';
export const LOCATION_SET = 'LOCATION_SET';
export const LAT_LOCATION_SET = 'LAT_LOCATION_SET';
export const LOCATION_SET_LAST = 'LOCATION_SET_LAST';
export const LOCATION_SET_MESSAGE = 'LOCATION_SET_MESSAGE';
// LOADING
export const LOADING = 'LOADING';
// STORAGE
// export const BROWSER_TOKEN_STORAGE = "_c0k3t0J_zBen_";  // For Encryt Server
export const BROWSER_ACCESS_TOKEN_STORAGE = "_c0k3t1J_zBen_";  // For Encryt Server
export const BROWSER_REFRESH_TOKEN_STORAGE = "_c0k3t2J_zBen_";  // For Encryt Server
export const UPLOAD_TIME_STORAGE = "_t1m3_";  // For Encryt Server

export const CHANGE_PASSWORD_STORAGE = "_i5ch4n9e9_";   // For checking isChangePassword when first time login page
// export const SECRET_KEY_TOKEN = "authen_token";     // For Encrypt client
export const SECRET_KEY_ACCESS_TOKEN = "auth_access_token";     // For Encrypt client
export const SECRET_KEY_REFRESH_TOKEN = "auth_refresh_token";     // For Encrypt client

export const INFO_USER_KEY_TOKEN = "info_user_token";     // For Encrypt client
export const SECRET_KEY_EVEN_STORE = "event_store";     // For Encrypt client
export const EVENT_STORAGE = '_3Ve8N1D_';   // Keep Event when the store page is re-loaded (prevent to back homepage)
export const STORETYPE_STORAGE = '_5t0r34e_'; // Keep StoreType when the store page is re-loaded (prevent to back homepage)
export const ONESIGNAL_PLAYER = '_5t0sdsffdawfr34e_';