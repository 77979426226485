import * as actionTypes from '../constants/actionTypes';

const initialState = {
    storeTypes: null,        // [{id: "", name: "", description: ""}]
    provinces: null,    // [{id: "", code: "", description: ""}]
    events: null,       // [{id: "", name: "", description: ""}]
    eventImages: null,
    channels: null,      // [{id: "", name: "", description: ""}]
    channelPackages: null,   // [{id: "", name: "", description: "", nameChannal: "", nameArea: ""}]
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SERVICE_INI:
            return state;
        case actionTypes.SERVICE_SET_STORE_TYPE:
            return {
                ...state,
                storeTypes: action.data
            }
        case actionTypes.SERVICE_SET_PROVINCE:
            return {
                ...state,
                provinces: action.data
            }
        case actionTypes.SERVICE_SET_EVENT:
            return {
                ...state,
                events: action.data
            }
        case actionTypes.SERVICE_SET_EVENT_IMAGES:
            return {
                ...state,
                eventImages: action.data
            }
        case actionTypes.SERVICE_SET_CHANNEL:
            return {
                ...state,
                channels: action.data
            }
        case actionTypes.SERVICE_SET_CHANNEL_PACKAGE:
            return {
                ...state,
                channelPackages: action.data
            }
        case actionTypes.SERVICE_SET_MESSAGE:
            return {
                ...state,
                message: action.data
            }
        default:
            return state;
    }
}

export default reducer;