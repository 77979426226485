// Page Title
export const TITLE_HOME = 'COKE AUDIT';
export const TITLE_EVENT_TET = 'AUDIT TẾT 2020';
export const TITLE_EVENT_NUTRI = 'AUDIT NUTRIBOOST';
export const TITLE_STORE_SFA = 'SFA';
export const TITLE_STORE_NON_SFA = 'NON - SFA';
// Type of event => components/fontend/page/home.js
export const EVENT_TET = 'EVENT_TET';
export const EVENT_NUTRI = 'EVENT_NUTRI';
// Type of store => components/fontend/page/home.js
export const STORE_SFA = 'STORE_SFA';
export const STORE_NON_SFA = 2;//'703e132f-db2c-4190-be5d-ffffd2b45ac6';
// parse ranks to images => components/fontend/ui/customer/rank.js
export const GOLD = 1;
export const SILVER = 2;
export const BRONZE = 3;
// Status of store
export const STATUS_ALL = 0;
export const STATUS_NOACTION = 1;
export const STATUS_WAITING_APPROVED = 2;
export const STATUS_PASS = 3;
export const STATUS_FAIL = 4;
// Status of survey
export const SURVEY_NONE = 0;
export const SURVEY_PASS = 1;
export const SURVEY_FAIL = 2;
export const SURVEY_REDO = 3;
export const SURVEY_PENDING = 4;
export const SURVEY_NOACTION = 5;
export const SURVEY_LAYER = 6;
export const SURVEY_24HLAYER = 7;
export const SURVEY_RTMM_OPEN_LAYER = 8;
export const SURVEY_LAYER_FAIL = 9;
// Survey Audit page options
export const SURVEY_PAGE_TYPE = '_';
// ResponseStatus api
export const RESPONSE_TOKEN_INVALID = 'TOKEN_INVALID';
export const RESPONSE_OK = 'SUCCESS';
export const RESPONSE_NOT_FOUND = 'NOT_FOUND';
export const RESPONSE_EXCEPTION = 999;
export const RESPONSE_NO_DATA_FOUND = 'Không tìm thấy dữ liệu';
export const RESPONSE_UPDATE_FAILED = 'Cập nhật dữ liệu không thành công';

export const STATUS_RESPONSE_OK = 200;
export const STATUS_RESPONSE_NON_AUTHORITATIVE_INFORMATION = 203;
export const STATUS_RESPONSE_BAD_REQUEST = 400;
export const STATUS_RESPONSE_UNAUTHORIZED = 401;
export const STATUS_RESPONSE_FORBIDDEN = 403;
export const STATUS_RESPONSE_NOT_FOUND = 404;
export const STATUS_RESPONSE_METHOD_NOT_ALLOWED = 405;
export const STATUS_RESPONSE_REQUEST_TIMEOUT = 408;
export const STATUS_RESPONSE_UNSUPPORTED_MEDIA_TYPE = 415;
export const STATUS_RESPONSE_INTERNAL_SERVER_ERROR = 500;
export const STATUS_RESPONSE_BAD_GATEWAY = 502;
export const STATUS_RESPONSE_SERVICE_UNAVAILABLE = 503;
export const STATUS_RESPONSE_GATEWAY_TIMEOUT = 504;
export const STATUS_RESPONSE_TOKEN_INVALID = 600;
export const STATUS_RESPONSE_USER_NOT_FOUND = 601;
export const STATUS_RESPONSE_USER_SUSPEND = 602;
export const STATUS_RESPONSE_FEATURE_SUSPEND = 603;
export const STATUS_RESPONSE_PERMISSION_DENIED = 604;
export const STATUS_RESPONSE_NO_DATA_FOUND = 605;
export const STATUS_RESPONSE_UPDATE_FAILED = 606;
export const STATUS_RESPONSE_EXCEPTION = 999;

export const OneSignalId = "54f518b6-d0f9-48d0-a1e4-36c6ccb8ead5"

export const version = 9;