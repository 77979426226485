// export const SERVICE_URI = "https://cokeapi.tengroup.com.vn";
// export const IMAGE_URL = "https://cokeapi.tengroup.com.vn";
export const PAGE_SIZE = 5;
export const MAX_WIDTH = 1000;
export const MAX_HEIGHT = 1000;

export function GetURL() {
  var enviroment = 2;
  var baseUrlLink = "https://api.windowaudit.com.vn/";
  switch (enviroment) {
    case 0:
      baseUrlLink = "http://localhost:23067";
      break;
    case 1:
      baseUrlLink = "https://api.windowaudit.com.vn";
      break;
    case 2:
      baseUrlLink = "https://testapi.windowaudit.com.vn";
      break;
    case 3:
        baseUrlLink = "https://localhost:44338";
        break;
    default:
      baseUrlLink = "";
      break;
  }
  return baseUrlLink;
}

export const SERVICE_URI = GetURL();
export const IMAGE_URL = GetURL();
// DEO0000 xoa
//  export const SERVICE_URI = "https://api.windowaudit.com.vn";
//  export const IMAGE_URL = "https://api.windowaudit.com.vn";
// 10743AA01
// srsfatest / 123456 -> test sfa

//  export const SERVICE_URI = "https://testapi.windowaudit.com.vn";
//  export const IMAGE_URL = "https://testapi.windowaudit.com.vn";

export const isCheckTimeImage = false; //check thời gian upload ảnh
export const isCheckPlatform = true; //Kiểm tra platform của thiết bị
