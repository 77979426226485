import * as config from '../../common/config';

export const AUTH_CODE_GET = `${config.SERVICE_URI}/check-token`;
export const USER_LOGIN = `${config.SERVICE_URI}/user-login`;

export const USER_LOGIN_JWT = `${config.SERVICE_URI}/user-login-jwt`;

export const USER_CHECK_TOKEN = `${config.SERVICE_URI}/user-check-token`;
export const USER_CHANGE_PASSWORD = `${config.SERVICE_URI}/user-change-password`;
export const USER_GET_INFO = `${config.SERVICE_URI}/user-get-info`;

export const GET_EVENTS_VALID_DATE = `${config.SERVICE_URI}/get-events-valid-date`;
export const GET_EVENT_IMAGES = `${config.SERVICE_URI}/get-event-images`;
export const GET_ALL_STORE_TYPE = `${config.SERVICE_URI}/get-all-store-type`;
export const GET_STORE_TYPE = `${config.SERVICE_URI}/get-store-type`;
export const GET_PROVINCES = `${config.SERVICE_URI}/get-provinces`;
export const GET_CHANNELS = `${config.SERVICE_URI}/get-channels`;     // GET CHANNELS BY EVENTID
export const GET_CHANNEL_PACKAGE_BY_CHANNELID = `${config.SERVICE_URI}/get-channel-package-by-channelId`;    // LẤY SUẤT (30-40)
export const GET_CHANNEL_PACKAGE_BY_EVENTID_CHANNELID = `${config.SERVICE_URI}/get-channel-package-by-eventid-and-channelid`;    // LẤY SUẤT (30-40)

export const GET_SURVEYS = `${config.SERVICE_URI}/get-surveys`;
export const GET_SURVEY_REDO = `${config.SERVICE_URI}/get-surveys-redo`;

export const REFRESH_TOKEN = `${config.SERVICE_URI}/refreshtoken`;

export const ADD_SURVEY = `${config.SERVICE_URI}/add-survey`;
export const ADD_SURVEYS = `${config.SERVICE_URI}/add-surveys`;
export const ADD_SURVEYS_URL = `${config.SERVICE_URI}/add-surveys-url`;
export const ADD_SURVEYS_LAYER = `${config.SERVICE_URI}/add-surveys-layer`;
export const ADD_SURVEYS_LAYER_URL = `${config.SERVICE_URI}/add-surveys-layer-url`;

export const REDO_SURVEYS_URL = `${config.SERVICE_URI}/redo-surveys-url`;

export const UPDATE_SURVEY_STATUS = `${config.SERVICE_URI}/update-survey-status`;
export const GET_SURVEY_IMAGES = `${config.SERVICE_URI}/get-survey-images`;
export const GET_SURVEY_IMAGES_LAYER = `${config.SERVICE_URI}/get-survey-images-layer`;
export const GET_SURVEY_IMAGES_FE = `${config.SERVICE_URI}/get-survey-images-fe`;
export const SUBMIT_SURVEY_LAYER = `${config.SERVICE_URI}/submit-layer`;

// export const STORE_GET_ALL = `${config.SERVICE_URI}/store-get-all`;
export const STORE_GET_ALL = `${config.SERVICE_URI}/store-get-all-sr`;
export const STORE_GET_DETAIL = `${config.SERVICE_URI}/store-get-detail`;
export const STORE_ADD = `${config.SERVICE_URI}/store-add`;

export const GET_LAST_LOCATION = `${config.SERVICE_URI}/get-last-location`;
export const UPDATE_LAST_LOCATION = `${config.SERVICE_URI}/update-last-location`;

//export const UPLOAD_IMAGE = `${config.SERVICE_URI}/upload-base64`;

export const ONESIGNAL_ADDTAGS = `https://onesignal.com/api/v1/players/`;

export const NOTIFICATION_LIST = `${config.SERVICE_URI}/list-notification`;

export const NOTIFICATION_READ = `${config.SERVICE_URI}/read-notification`;

export const NOTIFICATION_READALL = `${config.SERVICE_URI}/read-all-notification`;

/*2020-06-26*/
export const GET_DETAIL_SURVEYS_LAYER = `${config.SERVICE_URI}/detail-surveys-layer`;
export const UPDATE_SURVEYS_LAYER = `${config.SERVICE_URI}/update-survey`;
export const UPDATE_SURVEYS_LAYER_URL = `${config.SERVICE_URI}/update-survey-url`;
/** Upload File  **/
export const UPLOAD_IMAGE = `${config.SERVICE_URI}/upload-image-string`;
export const LOG_IMAGE = `${config.SERVICE_URI}/log-img`;


