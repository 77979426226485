import * as actionTypes from '../constants/actionTypes';

const initialState = {
    lastLat: null,
    lastLong: null,
    lat: null,
    long: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOCATION_INI:
            return {
                lastLat: null,
                lastLong: null,
                lat: null,
                long: null
            };
        case actionTypes.LOCATION_SET:
            return {
                ...state,
                lat: action.lat,
                long: action.long
            }
        case actionTypes.LOCATION_SET_LAST:
            return {
                ...state,
                lastLat: action.lat,
                lastLong: action.long
            }
        default:
            return state;
    }
}

export default reducer;