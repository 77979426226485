import React from 'react';
import NavMenu from './nav';
import FooterPage from './footer';

const Template = ({windowHeight, children, role}) => {
    return (
        <div style={{ width: "100%", minHeight: windowHeight - 50, overflowX: "hidden" }}>
            <NavMenu role={role}/>
            <div style={{ width: "100%", minHeight: windowHeight - 50}}>
                {children}
            </div>
            <FooterPage/>
        </div>
    )
}
export default Template;