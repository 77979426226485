import * as actionTypes from '../constants/actionTypes';

const initialState = {
    lat: null,
    long: null,
    message: "",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LAT_LOCATION_INI:
            return {
                lat: null,
                long: null
            };
        case actionTypes.LAT_LOCATION_SET:
            return {
                ...state,
                lat: action.lat,
                long: action.long
            }
        case actionTypes.LOCATION_SET_MESSAGE:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;