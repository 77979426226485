import * as actionTypes from '../constants/actionTypes';
// import * as e from '../../common/enum';

const initialState = {
    data: [],
    //  status: e.STATUS_ALL,
    // pageIndex: 1,
    // pageTotal: 1,
    pageRows: 0,
    isLoading: false,
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATION_LIST_INI:
            return state;
        case actionTypes.NOTIFICATION_LIST_SET:
            // console.log(action);
            let listNoti = []
            // let listNoti = state.data.concat(action.data);
            if(action.pageRows === 0){
                listNoti = action.data
            }else{
                // console.log(2);
                listNoti = state.data.concat(action.data);
            }
            // console.log(action, listNoti)
            return {
                data: listNoti,
                pageRows: listNoti.length,
                message: null
            }
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.loading
            }
        case actionTypes.NOTIFICATION_LIST_ERROR:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;