import * as actionTypes from '../constants/actionTypes';
import * as e from '../../common/enum';

const initialState = {
    data: null,
    code: '',
    status: e.STATUS_ALL,
    pageIndex: 1,
    pageTotal: 1,
    isLoading: false,
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_LIST_INI:
            return state;
        case actionTypes.STORE_LIST_SET:
            return {
                data: action.data,
                code: action.code,
                status: action.status,
                pageIndex: action.pageIndex,
                pageTotal: action.pageTotal,
                message: null
            }
        case actionTypes.LOADING:

            return {
                ...state,
                isLoading: action.loading
            }
        case actionTypes.STORE_LIST_ERROR:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;