import * as actionTypes from '../constants/actionTypes';
import * as router from '../../common/route';

const initialState = {
    title: null,
    router: router.HOME,
    event: localStorage.getItem(actionTypes.EVENT_STORAGE), // { id: null, name: null },
    storeType: localStorage.getItem(actionTypes.STORETYPE_STORAGE) // { id: null, name: null },
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMMON_INI:
            return state;
        case actionTypes.COMMON_SET_TITLE:
            return {
                ...state,
                title: action.title
            }
        case actionTypes.COMMON_SET_ROUTER:
            return {
                ...state,
                router: action.router
            }
        case actionTypes.COMMON_SET_EVENT:
            localStorage.setItem(actionTypes.EVENT_STORAGE, JSON.stringify(action.event));
            return {
                ...state,
                event: action.event
            }
        case actionTypes.COMMON_SET_STORETYPE:
            localStorage.setItem(actionTypes.STORETYPE_STORAGE, JSON.stringify(action.storeType));
            return {
                ...state,
                storeType: action.storeType
            }
        case actionTypes.COMMON_SET_EVENT_STORETYPE:
            localStorage.setItem(actionTypes.EVENT_STORAGE, JSON.stringify(action.event));
            localStorage.setItem(actionTypes.STORETYPE_STORAGE, JSON.stringify(action.storeType));
            return {
                ...state,
                event: action.event,
                storeType: action.storeType
            }
        default:
            return state;
    }
}

export default reducer;