
export const HOME = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const CHANGE_PASSWORD = '/change-password';
export const STORE_ADD = '/add-non-sfa';
export const STORE_DETAIL = '/store/detail';
export const STORES = '/stores';
export const SURVEY = '/survey';
export const REDO_SURVEY = '/redo-survey';
export const REDOSURVEYLAYER = '/redolayer';
export const EDITSURVEYLAYER = '/editlayer';
export const SURVEY_AFTER = '/survey-detail';
export const LIST_NOTI = '/notification';