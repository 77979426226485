import * as actionTypes from '../constants/actionTypes';

const initialState = {
    data: null,
    dataCreate: null,
    imgLightbox: [],
    isLoading: false,
    message: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_DETAIL_INI:
            return {
                data: null,
                imgLightbox: [],
                isLoading: false,
                message: null
            };
        case actionTypes.STORE_DETAIL_SET:
            return {
                ...state,
                data: action.data,
                isLoading: false,
                message: null
            };
        case actionTypes.STORE_DETAIL_CREATE:
            return {
                ...state,
                dataCreate: action.data,
                isLoading: false,
                message: null
            };
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.loading
            };
        case actionTypes.STORE_DETAIL_ERROR:
            return {
                ...state,
                isLoading: false,
                message: action.message
            };
        default:
            return state;
    }
};
export default reducer;