import React, { Fragment } from 'react'
const moment = require("moment");
const FooterPage = () => {
    return (
        <Fragment>
            <hr />
            <footer className="footer-page text-center d-flex justify-content-between align-items-center">
                <div className="logo-ten-software d-flex align-items-center">
                    <a rel="noopener noreferrer" href="https://tengroup.com.vn/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/img/logo-ten.jpg`} alt="logo" />
                    </a>
                </div>
                <div className="align-items-center justify-content-between d-flex">
                    <span>&copy; Copyright {moment().year()}</span>
                    <a rel="noopener noreferrer" href="https://tengroup.com.vn/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/img/logo1.png`} alt="logo" style={{ width: 20, objectFit: "contain" }} />
                        <img src={`${process.env.PUBLIC_URL}/img/logotext.png`} alt="logo" style={{ height: 12, objectFit: "contain" }} /></a>
                </div>
            </footer>
        </Fragment>
    )
}

export default FooterPage;