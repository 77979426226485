import React, { useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Button } from "react-bootstrap";
import * as router from "../common/route";
import { Divider } from "primereact/divider";
// import * as types from "../store/constants/actionTypes";

const NavBar = ({ role }) => {
  // console.log("role", role);
  const [expanded, setExpanded] = useState(false);
  return (
    <Navbar expand="md" className="nav-menu-bar" expanded={expanded}>
      <Navbar className="nav-item-back d-none">
        <Button className="btn btn-default btn-back">
          <span className="pi pi-arrow-left"></span>
        </Button>
      </Navbar>
      <Navbar.Brand className="nav-menu-logo-img">
        <Link to={router.HOME}>
          <img
            src={`${process.env.PUBLIC_URL}/img/web-18.png`}
            alt="logo"
            className="nav-menu-logo"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={() => setExpanded(!expanded)}
      />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Divider className="d-md-none nav-divider" />
          <Link
            to={router.HOME}
            className="nav-item-coca"
            onClick={() => setExpanded(!expanded)}
          >
            <span>DANH SÁCH CHƯƠNG TRÌNH</span>
          </Link>
          <Divider className="d-md-none nav-divider" />
          {role && role !== 9 && (
            <Fragment>
              <Link
                to={router.CHANGE_PASSWORD}
                className="nav-item-coca"
                onClick={() => setExpanded(!expanded)}
              >
                <span>ĐỔI MẬT KHẨU</span>
              </Link>
              <Divider className="d-md-none nav-divider" />
            </Fragment>
          )}
          <Link
            to={router.LOGOUT}
            className="nav-item-coca"
            onClick={() => {
              setExpanded(!expanded);
              localStorage.clear();
              window.location.href = "/";
            }}
          >
            <span>ĐĂNG XUẤT</span>
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
