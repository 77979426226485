import * as actionTypes from '../constants/actionTypes';
import { deCryptUserId } from '../../crypto/Crypto';

function checkForceChangePassword() {
    let forceChangePassword = localStorage.getItem(actionTypes.CHANGE_PASSWORD_STORAGE);
    if (forceChangePassword) {
        return false;
    }
    else {
        return true;
    }
}

const initialState = {
    userId: deCryptUserId(),
    userInfo: null,
    isChangePassword: checkForceChangePassword(),    // for the first time of changing password
    isLoading: false,
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ACCOUNT_INI:
            return {
                userId: deCryptUserId(),
                userInfo: null,
                isChangePassword: checkForceChangePassword(),    // for the first time of changing password
                isLoading: false,
                message: null
            };
        case actionTypes.ACCOUNT_SET:
            return {
                ...state,
                userInfo: action.user,
                isLoading: false,
                message: null
            }
        case actionTypes.ACCOUNT_SET_CHANGE_PASSWORD:
            return {
                ...state,
                isLoading: false,
                isChangePassword: true
            }
        case actionTypes.ACCOUNT_MESSAGE:
            return {
                ...state,
                userId: null,
                isLoading: false,
                message: action.message
            }
        default:
            return state;
    }
}

export default reducer;