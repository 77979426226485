import "./style.scss";
// import 'primeflex/primeflex.scss'
import React, { Suspense, lazy, useEffect, useState } from "react";
// import { connect } from "react-redux";
import { Route, Redirect, Switch, useHistory } from "react-router-dom";
// import {
//   CHANGE_PASSWORD_STORAGE,
//   INFO_USER_KEY_TOKEN,
// } from "./store/constants/actionTypes";
import * as router from "./common/route";
import Layout from "./shared/template";
import LayoutLogin from "./shared/templateLogin";
// import {Container, Row, Col} from 'react-bootstrap';
import { Digital } from "react-activity";
import { checkAppVersion } from "./common/function";
import { useSelector } from "react-redux";
// import { deCryptClient } from "./crypto/Crypto";
// import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import ReactGA from "react-ga";
ReactGA.initialize("UA-33799892-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Login = lazy(() => import("./view/login"));
const Logout = lazy(() => import("./components/fontend/pages/Logout"));
const Home = lazy(() => import("./view/event/listevent"));
const ChangePassword = lazy(() => import("./view/user/changePassword"));
const ListStore = lazy(() => import("./view/store/liststore"));
const StoreAdd = lazy(() => import("./view/store/addStore"));
const StoreDetail = lazy(() => import("./view/store/storeDetail"));
// const SurveyAudit = lazy(() => import('./components/fontend/pages/SurveyAuditUrl'));
const SurveyAudit = lazy(() => import("./view/survey/surveyAudit"));
const RedoSurvey = lazy(() => import("./view/survey/redoSurvey"));
const RedoSurveyLayer = lazy(() => import("./view/survey/redoLayer"));
const EditSurveyLayer = lazy(() => import("./view/survey/editLayer"));
const SurveyAuditAfter = lazy(() => import("./view/survey/surveyAuditAfter"));
// const ListNoti = lazy(() => import('./components/fontend/pages/Notification'));

const App = () => {
  // let { isAuth, isChangePassword } = props;
  const history = useHistory();

  // let account = useSelector((x) => x.account.userInfo);
  // let auth = useSelector((x) => x.auth);
  const isAuth = useSelector((x) => x.auth.accessToken !== null);
  const isChangedPassword = useSelector((x) => x.auth.isChangedPassword);
  const accessToken = useSelector((x) => x.auth.accessToken);
  // const history = useHistory();

  // console.log("auth", auth);

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const [role, setRole] = useState(true); 

  useEffect(() => {
    checkAppVersion();

    // let userLogin = localStorage.getItem("D2ycSm9i1G");
    // // deCryptClient(
    // //   ,
    // //   INFO_USER_KEY_TOKEN
    // // );
    // if (account) {
    //   userLogin = account;
    // }

    // if (typeof userLogin === "string") {
    //   userLogin = JSON.parse(deCryptClient(userLogin, INFO_USER_KEY_TOKEN));
    // }

    // console.log("userLogin", userLogin);
    // if (userLogin && Object.keys(userLogin).length > 0) {
    //   const { level } = userLogin;
    //   setRole(level);
    // }
    
    if(accessToken){
      const decode = jwt_decode(accessToken);
      // console.log("decode", decode);
      const {roleId} = decode;
      setRole(parseInt(roleId));
    }
    //eslint-disable-next-line
  }, [accessToken]);

  useEffect(() => {
    // const isChangedPass = isChangedPassword || localStorage.getItem(CHANGE_PASSWORD_STORAGE);
    // console.log("isChangedPass", isChangedPass, isChangedPassword);
    if(isChangedPassword) {
      history.push(router.CHANGE_PASSWORD);
    }
    // console.log("isChangedPassword", isChangedPass);
    // if (isChangedPass) {
    //   nav
    // }
    //eslint-disable-next-line
  }, [isChangedPassword]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const Loader = () => (
    <div style={{ width: windowWidth, height: windowHeight }}>
      <Digital
        color="#727981"
        size={windowHeight / 15}
        speed={1}
        animating={true}
        style={{ paddingTop: windowHeight / 2 - 100 }}
      />
    </div>
  );

  let route = (
    <LayoutLogin windowWidth={windowWidth} windowHeight={windowHeight}>
      <Switch>
        <Route
          path={router.LOGIN}
          render={(props) => (
            <Login
              windowWidth={windowWidth}
              windowHeight={windowHeight}
              {...props}
            />
          )}
        />
        <Redirect to={router.LOGIN} />
      </Switch>
    </LayoutLogin>
  );

  if (isAuth) {
    // if (isChangePassword) {
    //   route = (
    //     <LayoutLogin windowWidth={windowWidth} windowHeight={windowHeight}>
    //       <Switch>
    //         <Route
    //           path={router.CHANGE_PASSWORD}
    //           render={(props) => (
    //             <ChangePassword isChangedPassword={false} {...props} />
    //           )}
    //         />
    //         <Route path={router.LOGOUT} component={Logout} />
    //         <Redirect to={router.CHANGE_PASSWORD} />
    //       </Switch>
    //     </LayoutLogin>
    //   );
    // } else {
    if (role === 9) {
      route = (
        <Layout
          role={role}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        >
          <Switch>
            <Route
              exact
              path={router.HOME}
              render={(props) => (
                <Home
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  {...props}
                />
              )}
            />
            <Route path={router.LOGOUT} component={Logout} />
            <Route path={router.STORE_ADD} component={StoreAdd} />
            <Route path={router.STORES} component={ListStore} />
            <Route
              exact
              path={`${router.STORE_DETAIL}/:id`}
              render={(props) => <StoreDetail {...props} />}
            />
            <Route
              exact
              path={`${router.SURVEY}/:id`}
              render={(props) => <SurveyAudit {...props} />}
            />
            <Route
              exact
              path={`${router.REDO_SURVEY}/:id`}
              render={(props) => <RedoSurvey {...props} />}
            />
            <Route
              exact
              path={`${router.REDOSURVEYLAYER}/:id`}
              render={(props) => <RedoSurveyLayer {...props} />}
            />
            <Route
              exact
              path={`${router.EDITSURVEYLAYER}/:id`}
              render={(props) => <EditSurveyLayer {...props} />}
            />
            <Route
              exact
              path={`${router.SURVEY_AFTER}/:id`}
              render={(props) => <SurveyAuditAfter {...props} />}
            />
            <Redirect to={router.HOME} />
          </Switch>
        </Layout>
      );
    } else {
      route = (
        <Layout
          role={role}
          windowWidth={windowWidth}
          windowHeight={windowHeight}
        >
          <Switch>
            <Route
              exact
              path={router.HOME}
              render={(props) => (
                <Home
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                  {...props}
                />
              )}
            />
            {/* <Route exact path={router.LIST_NOTI} component={ListNoti} /> */}
            <Route path={router.LOGOUT} component={Logout} />
            <Route path={router.CHANGE_PASSWORD} component={ChangePassword} />
            <Route path={router.STORE_ADD} component={StoreAdd} />
            <Route path={router.STORES} component={ListStore} />
            <Route
              exact
              path={`${router.STORE_DETAIL}/:id`}
              render={(props) => <StoreDetail {...props} />}
            />
            <Route
              exact
              path={`${router.SURVEY}/:id`}
              render={(props) => <SurveyAudit {...props} />}
            />
            <Route
              exact
              path={`${router.REDO_SURVEY}/:id`}
              render={(props) => <RedoSurvey {...props} />}
            />
            <Route
              exact
              path={`${router.REDOSURVEYLAYER}/:id`}
              render={(props) => <RedoSurveyLayer {...props} />}
            />
            <Route
              exact
              path={`${router.EDITSURVEYLAYER}/:id`}
              render={(props) => <EditSurveyLayer {...props} />}
            />
            <Route
              exact
              path={`${router.SURVEY_AFTER}/:id`}
              render={(props) => <SurveyAuditAfter {...props} />}
            />
            <Redirect to={router.HOME} />
          </Switch>
        </Layout>
      );
    }
  }
  // }

  return <Suspense fallback={<Loader />}>{route}</Suspense>;
};

// const mapStateToProps = (state) => {
//   return {
//     isAuth: state.auth.token !== "",
//     isChangePassword: localStorage.getItem(CHANGE_PASSWORD_STORAGE),
//   };
// };

export default App;

// export default connect(mapStateToProps, null)(withRouter(App));
