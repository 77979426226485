import { AUTH_CODE_GET } from '../store/constants/serviceEndpoint';
import { 
    // BROWSER_TOKEN_STORAGE, 
    BROWSER_ACCESS_TOKEN_STORAGE,
    BROWSER_REFRESH_TOKEN_STORAGE,
    // SECRET_KEY_TOKEN 
    SECRET_KEY_ACCESS_TOKEN,
    SECRET_KEY_REFRESH_TOKEN,
    UPLOAD_TIME_STORAGE
} from '../store/constants/actionTypes';
import SimpleCrypto from "simple-crypto-js";
import { setMaxDigits } from './BigInt';
import { RSAKeyPair, base64encode, encryptedString } from './RSA';
import jwt from "jsonwebtoken";
var CryptoJS = require("crypto-js");


export function encryptAES(cipherText) {
    var key = CryptoJS.enc.Utf8.parse('13383B09B7AF47C4A408834779C72712');  
    var iv = CryptoJS.enc.Utf8.parse('8080808080808080');  
    var encryptedlogin = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(cipherText), key,  
    {
        keySize: 128 / 8,   
        iv: iv,  
        mode: CryptoJS.mode.CBC,  
        padding: CryptoJS.pad.Pkcs7 
    });  
    return encryptedlogin;
}

export function enCryptServer(args) {
    // let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
    let browserToken = localStorage.getItem(BROWSER_ACCESS_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_e"] && data["_m"] && data["_c"] && data["_i"]) {
            setMaxDigits(131);
            let key = new RSAKeyPair(data["_e"], "", data["_m"]);
            let encodeArgs = "";
            for (let i = 0; i < args.length; i++) {
                encodeArgs += "\\" + base64encode(args[i]);
            }
            let posx = encryptedString(key, data["_c"] + encodeArgs);
            return { id: data["_i"], posx: posx };
        }
        else {
            return null;
        }
    }
    return null;
}

export function getCode(id) {
    fetch(AUTH_CODE_GET, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: id })
    }).then(response => {
        return response.json();
    }).then((data) => {
        if (data) {
            console.log(data)
            // let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
            let browserToken = localStorage.getItem(BROWSER_ACCESS_TOKEN_STORAGE);
            data["_i"] = id;
            if (browserToken) {
                let code = JSON.parse(browserToken);
                if (code["_t"]) {
                    let decipherText = deCryptLoginTokenAndUserName();
                //    console.log(decipherText)
                    // data["_t"] = enCryptClient(decipherText, SECRET_KEY_TOKEN);
                    data["_t"] = enCryptClient(decipherText, SECRET_KEY_ACCESS_TOKEN);
                    console.log(data["_t"])
                }
                else {
                    // data["_t"] = enCryptClient(",", SECRET_KEY_TOKEN);
                    data["_t"] = enCryptClient(",", SECRET_KEY_ACCESS_TOKEN);

                }
            }
            else {
                // data["_t"] = enCryptClient(",", SECRET_KEY_TOKEN);
                data["_t"] = enCryptClient(",", SECRET_KEY_ACCESS_TOKEN);
            }
            // console.log(data)
            // localStorage.setItem(BROWSER_TOKEN_STORAGE, JSON.stringify(data));
            localStorage.setItem(BROWSER_ACCESS_TOKEN_STORAGE, JSON.stringify(data));
        }
    }).catch(err => {
        console.log(err);
    });
}

/**
 * encrypt and storage success info (loginToken, loginUser) by [_t] with value "[loginToken],[userName]"
 * @param {*} loginToken successful loginToken
 * @param {*} loginUser username
 */
// export function updateLoginTokenEncrypt(loginToken, userName, uploadTime) {
//     let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
//     if (browserToken) {
//         let data = JSON.parse(browserToken);
//         let auth = `${loginToken},${userName},${uploadTime}`;
//         data["_t"] = enCryptClient(auth, SECRET_KEY_TOKEN);
//         localStorage.setItem(BROWSER_TOKEN_STORAGE, JSON.stringify(data));
//     }
// }

export function updateLoginTokenEncrypt(loginToken, uploadTime) {
    let browserToken = localStorage.getItem(BROWSER_ACCESS_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        let auth = `${loginToken},${uploadTime}`;
        data["_t"] = enCryptClient(auth, SECRET_KEY_ACCESS_TOKEN);
        localStorage.setItem(BROWSER_ACCESS_TOKEN_STORAGE, JSON.stringify(data));
    }
}

/**
 * decrypt and get userId in localStorage
 */
// export function deCryptUserId() {
//     let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
//     if (browserToken) {
//         let data = JSON.parse(browserToken);
//         if (data["_t"]) {
//             let _t = deCryptClient(data["_t"], SECRET_KEY_TOKEN);
//             let parseTokenArr = _t.split(',');
//             // console.log(`UserName: ${parseTokenArr[1]}`);
//             return parseTokenArr[1];
//         }
//         return "";
//     }
//     else {
//         return "";
//     }
// }

export function deCryptUserId() {
    let browserToken = localStorage.getItem(BROWSER_ACCESS_TOKEN_STORAGE);
    if (browserToken) {
        // console.log(browserToken)
        const decode = jwt.decode(browserToken);
        // console.log(decode)
        if(decode)
            return decode.userId;
        else
            return "";
        // let data = JSON.parse(browserToken);
        // if (data["_t"]) {
        //     let _t = deCryptClient(data["_t"], SECRET_KEY_ACCESS_TOKEN);
        //     let parseTokenArr = _t.split(',');
        //     return parseTokenArr[1];
        // }
        // return "";
    }
    else {
        return "";
    }
}

// /**
//  * decrypt and get loginToken in localStorage
//  */
// export function deCryptLoginToken() {
//     let browserToken = localStorage.getItem(BROWSER_TOKEN_STORAGE);
//     if (browserToken) {
//         let data = JSON.parse(browserToken);
//         if (data["_t"]) {
//             let _t = deCryptClient(data["_t"], SECRET_KEY_TOKEN);
//             let parseTokenArr = _t.split(',');
//             // console.log(`Token: ${parseTokenArr[0]}`);
//             return parseTokenArr[0];
//         }
//         return "";
//     }
//     else {
//         return "";
//     }
// }

export function deCryptLoginToken() {
    let browserToken = localStorage.getItem(BROWSER_ACCESS_TOKEN_STORAGE);
    if (browserToken) {
        return browserToken;
    }
    else {
        return "";
    }
}

/**
 * decrypt and get loginToken in localStorage
 */
// export function deCryptLoginAccessToken() {
//     let browserToken = localStorage.getItem(BROWSER_ACCESS_TOKEN_STORAGE);
//     if (browserToken) {
//         let data = JSON.parse(browserToken);
//         if (data["_t"]) {
//             let _t = deCryptClient(data["_t"], SECRET_KEY_TOKEN);
//             let parseTokenArr = _t.split(',');
//             // console.log(`Token: ${parseTokenArr[0]}`);
//             return parseTokenArr[0];
//         }
//         return "";
//     }
//     else {
//         return "";
//     }
// }
export function deCryptLoginAccessToken() {
    let browserToken = localStorage.getItem(BROWSER_ACCESS_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_t"]) {
            let _t = deCryptClient(data["_t"], SECRET_KEY_ACCESS_TOKEN);
            let parseTokenArr = _t.split(',');
            // console.log(`Token: ${parseTokenArr[0]}`);
            return parseTokenArr[0];
        }
        return "";
    }
    else {
        return "";
    }
}

/**
 * decrypt and get loginToken in localStorage
 */
export function deCryptLoginRefreshToken() {
    let browserToken = localStorage.getItem(BROWSER_REFRESH_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_t"]) {
            let _t = deCryptClient(data["_t"], SECRET_KEY_REFRESH_TOKEN);
            let parseTokenArr = _t.split(',');
            // console.log(`Token: ${parseTokenArr[0]}`);
            return parseTokenArr[0];
        }
        return "";
    }
    else {
        return "";
    }
}



/**
 * decrypt and get uploadTime in localStorage
 */

export function deCryptUploadTime() {
    let timeUpload = localStorage.getItem(UPLOAD_TIME_STORAGE);
    if (timeUpload) {
        return timeUpload;
    }
    // if (browserToken) {
    //     let data = JSON.parse(browserToken);
    //     if (data["_t"]) {
    //         let _t = deCryptClient(data["_t"], SECRET_KEY_ACCESS_TOKEN);
    //         let parseTokenArr = _t.split(',');
    //         // console.log(`UploadTime: ${parseTokenArr[2]}`);
    //         return parseTokenArr[2];
    //     }
    //     return "";
    // }
    else {
        return "";
    }
}

/**
 * decrypt and get userName in localStorage
 */
export function deCryptLoginTokenAndUserName() {
    let browserToken = localStorage.getItem(BROWSER_ACCESS_TOKEN_STORAGE);
    if (browserToken) {
        let data = JSON.parse(browserToken);
        if (data["_t"]) {
            let _t = deCryptClient(data["_t"], SECRET_KEY_ACCESS_TOKEN);
            let parseTokenArr = _t.split(',');
            let loginToken = parseTokenArr[0];
            let uploadTime = parseTokenArr[1];
            // let userName = parseTokenArr[1];
            // let uploadTime = parseTokenArr[2]
            // console.log(parseTokenArr)
            // console.log(`${loginToken},${userName}`);
            // return `${loginToken},${userName},${uploadTime}`;
            return `${loginToken},${uploadTime}`;
        }
        return ",";
    }
    else {
        return ",";
    }
}

export function enCryptClient(decipherText, _secretKey) {
    var simpleCrypto = new SimpleCrypto(_secretKey);
    return simpleCrypto.encrypt(decipherText);
}

export function deCryptClient(cipherText, _secretKey) {
    var simpleCrypto = new SimpleCrypto(_secretKey);
    return simpleCrypto.decrypt(cipherText);
}