import * as actionTypes from '../constants/actionTypes';
// import { deCryptLoginRefreshToken, deCryptLoginToken } from '../../crypto/Crypto';

// const initialState = {
//     token: deCryptLoginToken(),
//     isLoading: false,
//     message: null
// }

// const reducer = (state = initialState, action) => {
//     switch (action.type) {
//         case actionTypes.AUTH_INI:
//             return {
//                 token: deCryptLoginToken(),
//                 isLoading: false,
//                 message: null
//             };
//         case actionTypes.AUTH_SUCCESS:
//             return {
//                 token: action.token,
//                 isLoading: false,
//                 message: null
//             }
//         case actionTypes.LOADING:
//             return {
//                 ...state,
//                 isLoading: action.loading
//             }
//         case actionTypes.AUTH_FAIL:
//             return {
//                 token: null,
//                 isLoading: false,
//                 message: action.message
//             }
//         default:
//             return state;
//     }
// }

const initialState = {
    accessToken: localStorage.getItem(actionTypes.BROWSER_ACCESS_TOKEN_STORAGE) ? localStorage.getItem(actionTypes.BROWSER_ACCESS_TOKEN_STORAGE) : null,
    refreshToken: localStorage.getItem(actionTypes.BROWSER_REFRESH_TOKEN_STORAGE) ? localStorage.getItem(actionTypes.BROWSER_REFRESH_TOKEN_STORAGE) : null,
    isLoading: false,
    isChangedPassword: localStorage.getItem(actionTypes.CHANGE_PASSWORD_STORAGE) ? localStorage.getItem(actionTypes.CHANGE_PASSWORD_STORAGE) === "true" ? true : false : false,
    message: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_INI:
            // console.log(deCryptLoginToken(), deCryptLoginRefreshToken());
            return {
                accessToken: null,
                refreshToken: null,
                isLoading: false,
                isChangedPassword: false,
                message: null
            }
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
            }
        case actionTypes.AUTH_CHANGE_PASSWORD:
            return {
                ...state,
                isChangedPassword: action.isChangedPassword
            }
        case actionTypes.LOADING:
            return {
                ...state,
                isLoading: action.loading
            }
        case actionTypes.AUTH_FAIL:
            return {
                accessToken: null,
                refreshToken: null,
                isLoading: false,
                isChangedPassword: false,
                message: action.message
            }
        default:
            return state;
    }
}


export default reducer;